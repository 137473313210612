// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import JobFetcher from './JobFetcher';

function Dashboard() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    Notification.requestPermission();  // Request notification permission on load
  }, []);

  return (
    <div>
      <h1>Job Dashboard</h1>
      <JobFetcher setJobs={setJobs} />
      <ul>
        {jobs.map(job => (
          <li key={job.id}>
            <Link to={`/job/${job.id}`}>{job.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Dashboard;
