// src/components/JobDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

function JobDetails() {
  const { id } = useParams();
  const [job, setJob] = useState({});

  useEffect(() => {
    const fetchJobDetails = async () => {
      const response = await fetch(`https://api.example.com/jobs/${id}`);
      const data = await response.json();
      setJob(data);
    };

    fetchJobDetails();
  }, [id]);

  return (
    <div>
      <h1>{job.title}</h1>
      <p>{job.description}</p>
      {/* Display more job details */}
    </div>
  );
}

export default JobDetails;
