import React, { useEffect } from 'react';
import * as PusherPushNotifications from "@pusher/push-notifications-web";

function JobFetcher({ setJobs }) {
  useEffect(() => {
    // Check if the client has already been initialized
    const isInitialized = localStorage.getItem('isBeamsInitialized');

    if (!isInitialized) {
      Notification.requestPermission()
        .then(permission => {
          if (permission === 'granted') {
            console.log('Notification permission granted.');

            const beamsClient = new PusherPushNotifications.Client({
              instanceId: '1ae9ab5f-0129-4388-ac5e-114471b65698',
            });

            beamsClient.start()
              .then(() => {
                // Register service worker and handle notification clicks
                navigator.serviceWorker.addEventListener('notificationclick', function(event) {
                  console.log('Notification click Received.', event.notification.data);

                  event.notification.close(); // Android needs explicit close.

                  if (event.notification.data && event.notification.data.link) {
                    window.open(event.notification.data.link, '_blank');
                  }
                });

                return beamsClient.getDeviceId();
              })
              .then(deviceId => {
                console.log("Successfully registered with Beams. Device ID:", deviceId);
                return beamsClient.addDeviceInterest("jobs");
              })
              .then(() => {
                // Mark initialization as complete
                localStorage.setItem('isBeamsInitialized', 'true');
              })
              .catch(console.error);
          } else {
            console.log('Unable to get permission to notify.');
          }
        })
        .catch(error => {
          console.log('Error getting permission for notifications', error);
        });
    } else {
      console.log("Pusher Beams already initialized.");
    }

    return () => {
      // Cleanup logic if necessary
      localStorage.removeItem('isBeamsInitialized');
    };
  }, [setJobs]);

  return null;
}

export default JobFetcher;
